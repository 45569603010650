import React from 'react';
import "./assets/styles/_index.scss"
import { ArrowLeft } from 'react-bootstrap-icons'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from "gatsby"
import HardrockReadMore from './HardrockReadMore';
import VideoPlayer from "../../videoplayer/VideoPlayer"

import prevArrow from '/utility/assets/icons/prevArrow.png'
import nextArrow from '/utility/assets/icons/nextArrow.png'

import HardRockeveloperLogo from "/utility/assets/hard-rock/our-properties/Hardrock.png";


const videoUrl = "https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
const thumbnailUrl = require("/utility/assets/common/video-thumbnail.png")

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '5px', height: '5px', backgroundImage: `url(${prevArrow})` }}
      onClick={onClick}
    />
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '5px', height: '5px', backgroundImage: `url(${nextArrow})` }}
      onClick={onClick}
    />
  );
}


const HardrockAboutSidebar = ({ isOpen, toggleRightSidebar }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },

    ],

  }


  return (
    <div className={`right-sidebar-about ${isOpen ? 'open' : ''}`}>

      <div className="aboutBackButton" onClick={toggleRightSidebar}><span>
        <Link to="/developers/hard-rock-developer/"><ArrowLeft /> Back</Link></span>
      </div>

      <div className='AboutTitleImage'>
        <Image
          src={HardRockeveloperLogo}
          alt="2"

        />

      </div>

      <div className="AboutExpo">
        <Container>
          <Row>
            <HardrockReadMore />
          </Row>
        </Container>
      </div>

      <div className="AboutRatingSection">
        <VideoPlayer
         videoUrl={videoUrl}
          thumbnailUrl={thumbnailUrl}
        />
      </div>


      <div className="AboutHallSection">
        <Button>Download Brochure</Button>
      </div>

    </div>
  );
};

export default HardrockAboutSidebar;