import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function HardrockReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>About Hard Rock Hotels:</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">As a beacon of world-class entertainment and hospitality, Hard Rock, seamlessly blends
unrivaled dining, live music, and immersive experiences. Rooted in a philosophy of authenticity,
independence, and environmental stewardship, Hard Rock embodies a commitment to
preserving wildlife and safeguarding our planet.</p>

            </p>

            {isShowMore && (
                 <p className="mb-0">
                <p className="AboutExpoPara"> Under the ownership of the Seminole Tribe of
Florida since 2007, Hard Rock's legacy of pioneering achievements continues to flourish, poised
for global expansion.</p>

            </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default HardrockReadMore;